import React from "react";

import { base } from "./privacypolicy.module.scss";

import Layout from "../layouts/Layout";

const PrivacyPolicyPage = props => {
  return (
    <Layout pageTitle="Privacy | Brandcrush">
      <div className={base}>
        <h1>Privacy Policy</h1>
        <h2>Brandcrush Pty Limited (“Brandcrush”)</h2>
        <p>Effective Date: This Privacy Policy was last updated on 30 November 2020</p>
        <p>
          <b>
            Please take the time to read our Privacy Policy as it contains information about how we
            manage personal information.
          </b>
        </p>
        <h2>We respect your privacy and are taking care of your personal information</h2>
        <p>
          Brandcrush Pty Ltd ACN 617 383 052 (Brandcrush) respects the privacy of your personal
          information and data. Our aim is to safeguard the privacy and security of your personal
          information whilst providing a personalised service that can meet your individual
          preferences and needs.
        </p>
        <p>
          Brandcrush has policies and procedures in place to ensure that personal information that
          we collect, store, use and disclose is dealt with in accordance with the Australian
          Privacy Principles (APPs) in the Privacy Act 1988 (Cth) and with regard to best-practice
          standards in other jurisdictions. This Privacy Policy generally describes the sorts of
          personal information held and for what purposes and how that information is collected,
          stored, used and disclosed. In this policy:
        </p>
        <p>
          <ul>
            <li>
              "Personal information" refers to any information or any opinion, whether true or not
              and whether recorded in a material form or not, from which an individual's identity is
              apparent or can reasonably be ascertained;
            </li>
            <li>
              "Sensitive information" refers to information about a person's racial or ethnic
              origin, political opinions or associations, religious beliefs or affiliations,
              philosophical beliefs, trade and professional memberships, sexual preferences or
              practices, criminal record or health, genetic or biometric information; and.
            </li>
          </ul>
        </p>
        <p>
          To ensure we are keeping up with changing legislation and protecting your personal
          information, this privacy policy may be updated from time to time (without notice). When
          updates are made, we will publish the current Privacy Policy on our website, and we'll
          also state when it was last updated (Effective Date). Please check our website regularly
          for the latest Privacy Policy.
        </p>
        <p>
          If you have any suggestions or concerns not addressed in this Privacy Policy, please
          contact our Privacy Officer. The contact details of the Privacy Officer are listed at the
          end of this policy.
        </p>
        <p>
          It is important to understand that the Brandcrush Platform may contain links to
          third-party websites or resources (Third-Party Services). Such Third-Party Services may be
          subject to different privacy practices. Brandcrush is not responsible or liable for the
          availability or accuracy of such Third-Party Services or the content, products, or
          services available from Third-Party Services. Links to such Third-Party Services are not
          an endorsement by Brandcrush of such Third-Party Services.
        </p>
        <h2>Why Brandcrush might collect personal information - how it is used</h2>
        <p>
          In order for Brandcrush to provide services to registered users (Members) and certain
          third parties who offer services (Activation Partners), we may need to collect personal
          information so we can:
        </p>
        <p>
          <ul>
            <li>Offer relevant products and/or services;</li>
            <li>
              Send reminders, accounts and invoices; develop and implement initiatives to improve
              and tailor our products and/or services (including by generating reports that enable
              us to assess who is utilising our products and services); and
            </li>
            <li>Conduct our business activities professionally and efficiently.</li>
          </ul>
        </p>
        <p>
          If we are not provided with all the personal information we request, we may be unable to
          provide you with our products and/or services.
        </p>
        <p>
          However, it is important to note that the information collected by Brandcrush (about
          Members and Activation Partners) is information that typically pertains to entities
          (companies, corporations etc.), not individuals.
        </p>
        <h2>The types of personal information Brandcrush might collect</h2>
        <p>Personal Information that we might collect includes but is not limited to:</p>
        <p>
          <ul>
            <li>Name and address details</li>
            <li>Email address</li>
            <li>Contact numbers (landline, mobile number)</li>
            <li>Social Handles</li>
            <li>
              Business payment details to facilitate payment of services (e.g. credit card or bank
              account information)
            </li>
          </ul>
        </p>
        <p>
          In addition, we may also passively collect information about visitors to our Websites,
          which includes but may not be limited to:
        </p>
        <p>
          <ul>
            <li>Date and time of the visit</li>
            <li>How long the visitors stayed on the site</li>
            <li>which pages were accessed</li>
          </ul>
        </p>
        <p>
          This information is gathered by various technologies which include but are not limited to
          server logs, cookies and internet tags.
        </p>
        <h2>The channels Brandcrush might use to collect personal information</h2>
        <p>
          Channels by which Brandcrush may collect information may include, but not be limited to:
        </p>
        <p>
          <ul>
            <li>
              The Brandcrush Platform and any websites used by Brandcrush to offer or deliver its
              services, including the website brandcrush.com (collectively, Website). (b)
              Brandcrush's web, mobile, tablet and other smart device applications and application
              program interfaces (collectively, Application); and (c) in writing or verbally when
              engaging with Brandcrush staff directly (d) publicly available sources including but
              not limited to;
            </li>
            <li>
              Social media platforms (including but not limited to Facebook, Twitter, Instagram)
              (ii) other online media
            </li>
          </ul>
        </p>
        <p>
          Please note that all related Brandcrush websites will adher to this Privacy Policy unless
          otherwise stated.
        </p>
        <h2>How we store your information</h2>
        <p>
          The protection of personal information is a priority for us. Any hard copy documents are
          stored securely, and access is limited to staff. Electronic information is stored within
          the Brandcrush Platform on secure servers protected in controlled facilities. This service
          may be performed on our behalf, and data may be hosted by our selected data storage
          providers. In some cases, these facilities may be overseas, including, but not limited to,
          New Zealand, the United States of America, India, the Philippines and the United Kingdom.
          <p></p>
          We are committed to maintaining:
          <p></p>
          <ul>
            <li>
              Safeguards to protect personal information against unauthorised use, disclosure,
              access, interference, modification, destruction and accidental loss.
            </li>
            <li>
              Industry standards for the security and protection of information. Personal
              information is stored securely, and access is restricted to authorised personnel only.
            </li>
            <li>
              Internal policies on managing personal information and staff training to ensure
              compliance with these policies. All our staff members are required to read this policy
              and understand their responsibilities regarding personal information.
            </li>
          </ul>
          <p></p>
          We will destroy any personal information that we hold which is no longer needed after a 5
          year period unless we are required by law to retain such personal information or where
          such information is required to continue to provide services to Members and Activation
          Partners.
        </p>
        <h2>Disclosure of information</h2>
        <p>We will not disclose personal information to a third party unless:</p>
        <p>
          <ul>
            <li>
              The disclosure is for a primary purpose for which the information was collected, or
              the disclosure is for a related purpose for which the information was collected, and
              the individual concerned would reasonably expect us to disclose their personal
              information for the related purpose (or a directly related purpose in the case of
              sensitive information);
            </li>
            <li>The individual concerned has consented to the disclosure;</li>
            <li>
              The third party is our affiliate, partner, contractor or authorised agent, in which
              case we will require them to disclose and to use the personal information only for the
              purpose for which it was disclosed;
            </li>
            <li>
              The third party is our affiliate, partner, contractor or authorised agent, in which
              case we will require them to disclose and to use the personal information only for the
              purpose for which it was disclosed;
            </li>
            <li>
              The disclosure is reasonably necessary for the establishment, exercise or defence of a
              legal or equitable claim; or
            </li>
            <li>The disclosure is permitted, required or authorised by or under law.</li>
            <li>
              Brandcrush is acquired in part or in full by a third party (e.g. by means of a merge,
              acquisition, sale of assets). Personal information may be disclosed to prospective
              purchasers and advisers for the sole purpose of the acquisition.
            </li>
          </ul>
        </p>
        <h2>Disclosing of information or transferring of data overseas</h2>
        <p>
          Brandcrush would typically not disclose your personal information to overseas recipients.
          However, data is stored on secure servers hosted by our selected data storage provides. In
          some cases, these servers may be overseas, including to the United States of America, the
          United Kingdom, New Zealand, the Philippines and India.
        </p>
        <p>
          <br />
          In the event that we do disclose information to an overseas recipient, we will take
          reasonable steps to ensure that the overseas recipient does not breach the APPs in
          relation to the disclosed personal information unless:
        </p>
        <p>
          <ul>
            <li>
              We reasonably believe that the recipient of the information is subject to legal
              obligations that uphold principles for the protection and fair handling of personal
              information that are substantially the same as the APPs;
            </li>
            <li>
              We are given consent by the individual concerned to do so, expressly or by
              implication, after they are expressly informed that the Act will not apply in relation
              to the management of personal information by the overseas recipient, and the
              individual may not be able to seek redress under the Act for any subsequent breaches
              of the APPs; or
            </li>
            <li>We are legally authorised or required to do so.</li>
          </ul>
        </p>
        <h2>Collecting sensitive information</h2>
        <p>
          We typically do not collect sensitive information about our Members or Activation
          Partners. However, where we are required to, and it is practicable to do so, we will seek
          your consent before collecting your sensitive information and inform you of the purpose of
          the collection at that time. Your consent for the collection of your sensitive information
          may be implied in limited circumstances.
        </p>
        <h2>Contact by Brandcrush</h2>
        <p>
          Brandcrush may use personal information to contact you about new products, services and
          other initiatives that we think may be of interest to you. If you prefer not to receive
          information about our services, you can contact our Privacy Officer and request to be
          removed from the relevant circulation list.
        </p>
        <p>
          We may require written confirmation of a request to be removed from our circulation list,
          for example, where legislation requires us to provide particular communications to the
          individual concerned.
        </p>
        <p>
          We will not disclose personal information to a third party to enable that party to direct
          market their products or services to an individual unless that individual has expressly
          consented to that disclosure.
        </p>
        <h2>Keeping your information up to date</h2>
        <p>
          We ask that you tell us of any changes to the personal information we hold about you. You
          can notify our Privacy Officer at any time to request that your personal information be
          amended or updated. We will then take reasonable steps to correct the information in the
          manner requested.
        </p>
        <p>
          If we consider that the personal information we retain does not require amendment, we will
          annotate the request on our files. In a situation where we may decide not to agree to a
          request to correct personal information, we will tell you in writing why we have not
          agreed to the correction request.
        </p>
        <h2>Access to personal information</h2>
        <p>
          We will generally allow individuals access to any personal information that we hold about
          them on request subject to any restrictions on access. We will try to give the individual
          concerned access in a form and manner that suits their needs. To request such access,
          please contact our Privacy Officer.
        </p>
        <p>
          We are entitled to restrict access to personal information in accordance with the
          Australian Privacy Principles. You may not be allowed access to any personal information
          we hold where access would reveal evaluative information generated by us in connection
          with a commercially sensitive decision-making process. Instead, we may give you an
          explanation for the decision rather than direct access to the information.
        </p>
        <p>
          If we have given you such an explanation and you believe that direct access to the
          evaluative information is necessary to provide a reasonable explanation of the reasons for
          the decision, we will, at your request, review the decision. Personnel other than the
          original decision-maker will conduct the review.
        </p>
        <p>
          Wherever direct access by you is impractical or inappropriate, we will discuss options
          with you. We reserve the right to charge a reasonable fee for the provision of this
          information (whether the information is held electronically or in hard copy).
        </p>
        <h2>What to do if you have a query or problem</h2>
        <p>
          Brandcrush is committed to constantly reviewing our procedures so that personal
          information is treated appropriately.
        </p>
        <p>
          If you have any questions about our Privacy Policy, wish to access any personal
          information that we hold about you or have a complaint, please contact our Privacy
          Officer:
        </p>
        <p>
          Brandcrush Privacy Officer c/- L4, 80 Market St, South Melbourne Victoria, Australia 3205
        </p>
      </div>
    </Layout>
  );
};

export default PrivacyPolicyPage;
